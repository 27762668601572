/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    img: "img",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/'], ['en', 'https://www.hear.com/hearing-loss/'], ['en-US', 'https://www.hear.com/hearing-loss/'], ['en-CA', 'https://ca.hear.com/hearing-loss/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The sounds of nature, conversations with friends and family, concert auditoriums – our ears play an integral part in making all these activities enjoyable. Additionally, our ears are a natural warning system, capable of detecting oncoming traffic, registering a possible home break-in, or alerting us of any other potential dangers. We sometimes take these everyday things for granted, only becoming fully aware of that wonderful and complex organ – the ear – when things appear to go wrong."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is a famous quote that says, “You don’t know what you’ve got until it’s gone.” However, you don’t have to wait for hearing loss to remind you how important it is to hear clearly. Our sense of hearing allows us to enjoy the sounds that surround us in our everyday lives, such as our favorite music and our grandchildren’s laughter."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Life is too short to live without the voices and sounds you cherish the most. The first thing to do when you are experiencing a hearing loss is to educate yourself. That is why you will find relevant information about hearing loss on this page for you to read at your leisure. If you would like more information, you can always speak to one of our hearing aid experts for free! Simply fill out the form or give us a call directly to learn how you can begin to hear better today!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-and-symptoms-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-and-symptoms-of-hearing-loss",
    "aria-label": "causes and symptoms of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes and symptoms of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It is not easy to admit that our hearing is not what it used to be. We are usually unaware of it until family and friends point it out – and this is the very moment to do something about it. However, studies show that in Canada, people wait an average of 7 years after noticing the first signs of hearing loss before actually doing something about it. The use of a hearing aid would have significantly improved the quality of life and health over that period, had they addressed it earlier."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can be gradual and subtle, similar to vision loss. But there is one big difference – there is no stigma attached to wearing glasses!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Everybody knows that exposure to loud noises can damage one’s hearing. Many aspects of modern life put us in demanding listening environments, such as the workplace, on the street, or at home. One example is a concert: most of us have experienced a temporary hearing difficulty after attending an extremely loud music event. Moreover, in the last few decades, more people have been using earphones almost exclusively to listen to music, which can increase the likelihood of hearing damage. It’s important to note that loss of hearing can be a result of a head injury, disease, infection, trauma, stress, diet. Genetics is also a common culprit."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, when is the right time to seek help? These common signs and symptoms are indicators that immediate help should be sought:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty following a conversation with more than two people at once"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty understanding what people are saying in loud environments, such as busy restaurants, cafes, shops, etc."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Other people’s voices seem muffled"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Listening to the TV at a high volume"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing in the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Group conversations are exhausting"), "\n"), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-hearing-loss",
    "aria-label": "types of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The medical community has identified three types of hearing loss: conductive, sensorineural, and combined. What they all have in common is that one’s hearing capacity is, in part, significantly reduced. This has a direct impact on a person’s quality of life, health, and social interactions. What you do to manage hearing loss depends on its severity. At the moment, hearing aids are the only means to treat hearing loss effectively.\nThis condition can affect many aspects of your life. Communication with family and friends, business meetings, telephone calls, or spending time with your children can become an unnecessary challenge. In some cases, you can even feel socially isolated. Family and friends, unfortunately, might not fully understand your hearing situation, which can lead to misunderstandings and feelings of frustration. Therefore, it is essential for the people around you – spouse, grandchildren, children, colleagues, friends – to understand what you are experiencing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/types-hearing-loss.png",
    alt: "Types of hearing loss",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Is it temporary or permanent?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s not easy to determine precisely when a hearing loss is permanent or a symptom that will clear up on its own. It is common for the ear to recover after brief noise trauma. One of the typical causes of permanent hearing loss is long-term exposure to high levels of noise. Many other factors, like nutrition and stress levels, also play a role in hearing health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Why are hearing aids so important?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing is an extremely important aspect of human perception. Many young people who listen to loud music on their earphones suffer from poor hearing. Hearing aids are the most effective way to help you hear better in any listening environment. Not only does one hear better, but spatial orientation also improves with the help of hearing aids. Modern devices can even play music directly to your smartphone. When your hearing aid is programmed by a provider, it will never be too loud; thus, you won’t have to worry about further ear damage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "consequences-of-hearing-difficulties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consequences-of-hearing-difficulties",
    "aria-label": "consequences of hearing difficulties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consequences of hearing difficulties"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "social-consequences",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#social-consequences",
    "aria-label": "social consequences permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Social consequences"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Sad-man.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Studies show that people feel unhappy and depressed when they decide not to wear hearing aids. Moreover, your concentration, self-esteem, and speech comprehension are significantly reduced.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "physical-consequences",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#physical-consequences",
    "aria-label": "physical consequences permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Physical consequences"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Sad-woman.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The physical effects include fatigue, exhaustion, headaches and stress. If you suspect a sign of hearing loss, please call us to schedule a hearing test appointment with a local hearing aid provider in your area.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "one-in-four-has-a-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#one-in-four-has-a-hearing-loss",
    "aria-label": "one in four has a hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "One in four has a hearing loss"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/csm_canada-hearing-loss.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people think that seniors are the only ones who experience this condition. However, this is a national problem that touches all age groups. Moreover, one in six Americans are affected by this phenomenon. In most cases, high frequencies are the first ones that go missing. After that, the ability to hear is in a state of gradual decline. An important fact to remember is that the brain has less support when can’t hear, which makes it difficult to distinguish sounds and words. Therefore, it is very important you address any signs of poor hearing early on.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "emergence-and-causes-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#emergence-and-causes-of-hearing-loss",
    "aria-label": "emergence and causes of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Emergence and causes of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing difficulties fall into three categories: sound conduction, sensorineural, and sound perception."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sound conduction difficulties reduce, or completely block, the sound waves entering the inner ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sensorineural difficulties involve a pathological change of the inner ear or auditory nerve. In some cases, the auditory nerve does not send the received signal to the brain properly."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sound perception difficulties indicate that the brain does not process the incoming signal correctly."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other causes of a hearing difficulty include diseases or certain medications. In the case of medical treatment, it is not necessarily the drug itself but the amount administered. An overdose of painkillers can have a damaging effect on one’s hearing that can be permanent. If an overdose occurred in the past, it is important to know the extent of inner ear damage to find an effective treatment."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
